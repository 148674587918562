.formControl {
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
  align-items: center;
  font-size: 14px;
  min-height: 43px;

  &.withIcon {
    padding-left: 47px;
  }
}

.formControlWrapper {
  position: relative;
}

.iconWrapper {
  max-width: 18px;
  position: absolute;
  left: 13px;
  top: 8px;
}
