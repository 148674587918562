@import '~global/style-config/colors';
@import '~global/style-config/media-query';

$navItemWidth: 213px;
$navHorizontalOffset: calc(calc(100vw - #{$navItemWidth}) / 2);

.courseComponentNavWrapper {
  --minWidth: 213px;

  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding-left: $navHorizontalOffset;

  @include on-md {
    padding-left: 0;
  }

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

.courseComponentNav {
  counter-reset: item;

  @include on-md {
    width: 100%;
  }

  .courseComponentItem {
    flex: 1;
    position: relative;
    align-items: center;
    scroll-snap-align: center;
    z-index: 1;
    min-width: $navItemWidth;

    &::before {
      counter-increment: item;
      content: counter(item);
      position: absolute;
      right: 20px;
      font-size: 50px;
      opacity: 0;
      color: $white;
      transition: opacity 0.15s ease;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      @include sm-only {
        margin-right: $navHorizontalOffset;
      }
    }

    &.active {
      z-index: 0;
      background: $gradient-primary;

      .title {
        color: $white !important;
      }

      &::before {
        opacity: 0.2;
      }

      &::after {
        content: '';
        width: 0;
        height: 0;
        bottom: -12px;
        position: absolute;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid #4498da;
      }
    }

    .itemWrapper {
      flex: 1;
      display: flex;
      border-right: 1px solid rgba($white, 0.1);
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    .titleWrapper {
      .title {
        color: rgba($white, 0.5);
        font-size: 14px;
        font-weight: 500;
        margin-right: 15px;
        max-width: 120px;
      }
    }

    .activeBg {
      top: -11px;
      left: -5%;
      height: 114px;
      position: absolute;
    }

    .statusWrapper {
      margin-right: 15px;
    }

    &.coach {
      &::before {
        right: 10px;
      }

      .statusWrapper {
        left: 5px;
      }
    }
  }
}
