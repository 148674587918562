@import '~global/style-config/colors';
@import '~global/style-config/media-query';

.sessionCard {
  .toggleComponent {
    padding: 20px;

    @include on-sm {
      padding: 20px 40px;
    }
  }

  .cardTitle {
    border-bottom: 1px solid $bluish-gray;
  }

  .collapse {
    padding: 20px;

    @include on-sm {
      padding: 20px 40px 20px 40px;
    }
  }
}
