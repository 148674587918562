@import '~global/style-config/colors';
@import '~global/style-config/media-query';

.table {
  background-color: $white;
  box-shadow: 0 3px 6px 0 rgba($solid-black, 0.05);
  border-radius: 5px;

  table {
    width: 100%;
  }

  * {
    color: $black;
  }

  a,
  a:hover {
    color: $primary-light;
  }

  th,
  td {
    padding: 20px 10px;
    text-overflow: ellipsis;
    overflow: hidden;

    @include on-xl {
      padding: 20px;
    }
  }

  td {
    vertical-align: middle;
  }

  th {
    border-top: 0;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;

    * {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}
