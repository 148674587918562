@import './style-config/colors';
@import './style-config/flex-columns';
@import './style-config/media-query';

$font-family: helvetica-neue;
$background-color: #333;
$main-color: #773be2;

// BOOTSTRAP CSS CUSTOMIZATION

$theme-colors: (
  'primary-light': $primary-light,
  'primary': $primary,
  'primary-dark': $primary-dark,
  'secondary': $secondary,
  'gray': $gray,
  'gray-dark': $gray-dark
);

$spacers: (
  2: 2px,
  5: 5px,
  8: 8px,
  10: 10px,
  12: 12px,
  13: 13px,
  15: 15px,
  20: 20px,
  24: 24px,
  25: 25px,
  30: 30px,
  35: 35px,
  40: 40px
);

@import 'bootstrap/scss/bootstrap';

// Customize class bootstrap here

.btn-secondary {
  color: $white;

  &:disabled {
    color: $white;
  }
}

.btn-outline-secondary {
  &:hover {
    color: $white;
  }
}

.tooltip {
  padding: 0;
  margin: 0.4rem 0;
  box-shadow: 0 0 20px 0 rgba($solid-black, 0.1);

  .arrow {
    bottom: -0.4rem;

    &::before {
      border-top-color: $white;
    }
  }

  &-inner {
    background-color: $white;
    color: $black;
    padding: 20px;
  }
}

.react-datepicker-wrapper {
  display: flex !important;
  input {
    display: none;
  }
}
.react-datepicker-popper {
  top: -28px !important;
  left: 10px !important;
}

.react-datepicker__triangle {
  display: none;
}

a,
a:hover {
  color: $primary-light;
}

.flex-1 {
  flex: 1;
}

// React select custom css

.select__menu {
  margin-top: 10px !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1) !important;

  .select__menu-list {
    overflow-y: visible !important;
  }

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    background: white;
    position: absolute;
    top: -10px;
    right: 10px;
    transform: rotate(45deg);
    z-index: -20;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.02);
  }
}

// Native HTML custom css
:root {
  --headerHeight: 88px;
  --mobileHeaderHeight: 67px;
}

body {
  background-color: $gray;
}

button {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  font-size: inherit;
  color: inherit;

  > * {
    pointer-events: none;
  }
}

// Reusable classes
.overflow--hidden {
  overflow: hidden;
}

.form__button--submit {
  width: 100%;

  @include on-sm {
    width: unset;
  }
}
