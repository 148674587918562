@import './style-config/colors';
@import './style-config/media-query';

.imageWrapper {
  width: 40px;
  height: 40px;

  img {
    width: 100%;
  }
}

.date {
  width: 100%;
}

.mobileTable {
  list-style: none;
  padding: 0 20px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);

  * {
    font-size: 12px;
  }

  .name {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }

  .subtext {
    color: $gray-dark;
  }

  .link {
    color: $primary-light;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  li {
    padding: 20px 0;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(214, 220, 222, 0.5);
    }
  }
}

.favicon {
  width: 30px;
  height: 30px;
}
