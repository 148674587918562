.autocomplete.dropdown {
  display: none;
  opacity: 0;
}

.autocomplete.dropdown.show {
  position: absolute;
  display: block;
  opacity: 1;
  width: 100%;
  max-height: 120px;
  overflow-y: scroll;
  z-index: 3;
}
