$oneOverTwelve: (1.5 / 12) * 100;
$twoOverTwelve: (2.5 / 12) * 100;
$threeOverTwelve: (3.5 / 12) * 100;
$fourOverTwelve: (4.5 / 12) * 100;
$fiveOverTwelve: (5.5 / 12) * 100;
$sixOverTwelve: (6.5 / 12) * 100;
$sevenOverTwelve: (7.5 / 12) * 100;
$eightOverTwelve: (8.5 / 12) * 100;
$nineOverTwelve: (9.5 / 12) * 100;
$tenOverTwelve: (10.5 / 12) * 100;
$elevenOverTwelve: (11.5 / 12) * 100;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$flexSizes: (
  '1-5': $oneOverTwelve,
  '2-5': $twoOverTwelve,
  '3-5': $threeOverTwelve,
  '4-5': $fourOverTwelve,
  '5-5': $fiveOverTwelve,
  '6-5': $sixOverTwelve,
  '7-5': $sevenOverTwelve,
  '8-5': $eightOverTwelve,
  '9-5': $nineOverTwelve,
  '10-5': $tenOverTwelve,
  '11-5': $elevenOverTwelve
);

@each $breakpoint, $size in $grid-breakpoints {
  @each $column, $flexSize in $flexSizes {
    .col-#{$breakpoint}-#{$column} {
      @media (min-width: $size) {
        flex: 0 0 #{$flexSize + '%'} !important;
        max-width: #{$flexSize + '%'} !important;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}

@each $column, $flexSize in $flexSizes {
  .col-#{$column} {
    flex: 0 0 #{$flexSize + '%'};
    max-width: #{$flexSize + '%'};
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
