.modalTitle {
  color: #2b334a;
  font-size: 18px;
}

.modalUserSummary {
  img {
    width: 40px;
    height: 40px;
  }

  > div > p + p {
    color: #9c9c9c;

    &::before {
      content: url('/assets/icons/email.svg');
      margin-right: 8px;
    }
  }
}

.courseNavWrapper {
  pointer-events: none;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
