@import '~global/styles';

$headerHeight: 67px;

.active {
  background-color: #f3f6fa;
}

.closeIconWrapper {
  z-index: 2;
}

.mobileMenu {
  position: fixed;
  top: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-color: $blue;
  z-index: 10;
  padding: 30px 0;
  background-image: url('/assets/images/menu-background.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  bottom: 0;
  overflow-y: auto;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.4s ease, opacity 0.4s ease;

  &.isOpen {
    transform: translateX(0);
    opacity: 1;
  }

  p,
  a,
  svg {
    color: $white;
  }

  .profile {
    display: flex;
    padding-bottom: 20px;
    align-items: center;
    border-bottom: 1px solid $dark-blue;

    .summary {
      padding: 9px 10px;

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
      }

      .subtitle {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .closeIconWrapper {
    position: absolute;
    top: 0;
    right: 0;
    height: $headerHeight;
    padding: 17px;
    display: flex;
    align-items: center;

    svg {
      path {
        stroke: $white;
      }
    }
  }

  .linksWrapper {
    padding: 20px 0;
    list-style: none;

    .link {
      padding-left: 26px;
      border-left: 4px solid transparent;

      &:not(.isActive) {
        svg {
          path,
          g {
            opacity: 0.9;
          }

          g {
            fill: $white;
          }
        }
      }

      a,
      div {
        color: $white;
        display: block;
        padding: 14px 0;
        text-decoration: none;
        opacity: 0.5;
        font-size: 18px;

        .iconWrapper {
          margin-right: 16px;
          display: inline-block;

          svg {
            width: 25px;
            height: 25px;
          }
        }
      }

      &.isActive {
        border-left: 4px solid $secondary;

        a {
          opacity: 1;
        }
      }
    }
  }
}

.leftMenu {
  .navLink {
    opacity: 0.7;
    border-left: 4px solid transparent;

    .iconWrapper {
      svg {
        width: 20px;
      }
    }

    &:hover,
    &.active {
      text-decoration: none;
      background-color: $primary-dark;
      opacity: 1;
    }

    &.active {
      border-left: 4px solid $secondary;
    }

    &:hover:not(.active) {
      .iconWrapper {
        svg * {
          fill: $secondary;
          stroke: $secondary;
        }
      }
    }
  }
}

.header {
  background-color: $white;

  &Title {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include on-md {
      font-size: 21px;
    }
  }

  @include sm-only {
    height: $headerHeight;
    box-shadow: 0 100px 80px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    background: white;
  }

  .profileImage {
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
  }

  .contentWrapper {
    display: flex;
  }

  .userName {
    font-size: 14px;
  }
}

.dashboardWrapper {
  .contentWrapper {
    flex: 1;
    padding-top: $headerHeight;
    overflow-x: hidden;

    @include on-md {
      padding-top: 0;
    }
  }
}
