.modalTitle {
  color: #2b334a;
  font-size: 18px;
}

.customTable {
  table {
    table-layout: auto;
  }
  td {
    &.dropdownTd {
      overflow: visible;
    }
  }

  .resourceLink {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.seeMoreBtn {
  min-width: 130px;
}

.label {
  margin-left: 10px;
}

.select {
  padding: 10 px;
}

.searchBar {
  max-width: 210px;
}

.avatar {
  position: relative;
  width: 50px;

  .close {
    right: 0px;
    position: absolute;
    background-color: #cecece;
    color: #06429c;
    border-radius: 94px;
    padding-left: 7px;
    padding-right: 7px;
  }

  .image {
    width: 50px;
  }
}

.clientImage {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-width: 40px;
}

.categoryInput {
  max-width: 240px;
}

.fileName {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
