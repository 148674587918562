@import '~global/style-config/colors';
@import '~global/style-config/media-query';

.singleUser {
  display: flex;
  .courseNav {
    flex: 0.85;
  }
  .coachingSessionButton {
    background-color: $white;
    color: $primary;
    border-radius: 5px;
    max-height: 82px;
    flex: 0.15;

    &:hover,
    &.active {
      background: $gradient-primary;
      color: $white;
    }
  }
  .sectionTitle {
    font-size: 18px;
  }
}

.rightSidebar {
  @include md-only {
    > div {
      border-top: 1px solid $bluish-gray;
      padding-top: 30px;
    }
  }

  @include on-md {
    border-left: 1px solid $bluish-gray;
  }

  .okrImage {
    width: 30px;
    height: 30px;
  }
}

.modalTitle {
  color: $dark-blue;
  font-size: 18px;
}

.clientImage {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-width: 40px;
}
