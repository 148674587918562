@import '~global/style-config/media-query';
@import '~global/style-config/colors';

.container {
  width: 100%;
  max-width: unset;
  min-height: 100vh;
  height: 100%;
  
  @include on-md {
    display: flex;
    flex-direction: column;
  }
}

.sidebar {
  height: 100%;
  
  .navLink {
    opacity: 0.7;

    &:hover,
    &.active {
      text-decoration: none;
      background-color: $primary-dark;
      opacity: 1;

      .iconWrapper {
        color: $secondary;
      }
    }
  }
}

.mainContent {
  padding: 15px;
  padding-top: calc(var(--mobileHeaderHeight) + 15px);

  @include on-sm {
    padding: 40px;
  }
 
  .sectionTitle {
    font-size: 18px;
  }
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  
  @include sm-only {
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  svg {
    width: 12px;
    height: 12px;
    
    @include on-sm {
      width: 6px;
      height: 6px;
      margin-right: 8px;
    }
  }

  span {
    display: none;

    @include on-sm {
      display: inline-block;
    }
  }
}

.placeholderIcon {
  position: absolute;
  left: 20px;
  top: 13px;
}

.dividerLine {
  $lineColor: #d6dcde;

  border-top: 1px solid $lineColor;
  margin-top: 7.5px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.urlFavicon {
  position: absolute;
  top: 18px;
}

.urlTitle {
  margin-left: 44px;
}

.courseProgress {
  margin-bottom: 30px;

  @include on-md {
    margin-bottom: 0;
  }
}
