.touchableOpacity {
  cursor: pointer;
}

.tooltipContainer {
  position: relative;
  display: inline-block;

  .tooltipTrigger {
    cursor: pointer;
    font-size: 16px;
  }

  .toolTip {
    position: absolute;
    top: 0; /* Position below the trigger */
    left: 0;
    background-color: #fff;
    padding: 8px;
    border-radius: 4px;
    display: none; /* Hide by default */
    z-index: 1000;
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  &:hover .toolTip {
    display: block; /* Show tooltip on hover */
  }
}
