.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .title {
    margin-top: 20px;
    color: #2B334A;
    font-size: 18px;
    margin-bottom: 8px;
    padding: 0 35px;
  }

  .desc {
    padding: 0 20px;
    margin-bottom: 20px;
  }

  > * {
    justify-self: center;
  }
  
}
