@import '~global/style-config/media-query';

.pieChartWrapper {
  max-height: 190px;
  max-width: 190px;
  margin: 0 auto; 

  @include on-md {
    margin-left: 0;
    margin-right: 65px;
  }
}

.legendWrapper {
  max-height: 190px;
  max-width: 190px;
}