@import '~global/styles';

.surveyWrapper {
  max-width: 800px;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;

  .row {
    flex: 1;
  }

  .contentWrapper {
    padding: 50px;
    text-align: center;

    .logo {
      @include md-only {
        width: 150px;
      }
    }

    .title {
      color: 'red' !important;
    }
  }

  .button {
    text-align: right;
    margin-bottom: 40px;
  }
}
