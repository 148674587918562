@import '~global/style-config/media-query';
@import '~global/style-config/colors';

.dropdownIcon {
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: rgba(41, 116, 193, 0.1);
  border: 0;
  
  &.isOpen {
    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    > g > g{
      stroke: $primary;
    }
  }
}

