@import 'global/style-config/colors';
@import 'global/styles';

html {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  color: $black;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  font-family: $font-family;
  font-size: 14px;
}

.link {
  color: $primary-light !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;

  &:hover {
    text-decoration: underline;
  }
}
