@import '~global/styles';

.formCheck {
  input {
    display: none;
  }

  input {
    & + label {
      padding-top: 2px;
      padding-left: 20px;
      cursor: pointer;

      &::before {
        position: absolute;
        left: 0;
        content: '';
        width: 16px;
        height: 16px;
        border: 1px solid $bluish-gray;
        border-radius: 3px;
        transition-duration: 0.3s;
      }
    }

    &:checked {
      & + label {
        font-weight: 400;

        &::before {
          background-image: url('~assets/icons/checkbox-active.svg');
          background-repeat: no-repeat;
          border: 0;
        }
      }
    }
  }
}
