@import '~global/style-config/colors';

.card {
  padding: 20px;
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 6px 0 rgba($solid-black, 0.05);
  box-shadow: 0 3px 6px 0 rgba($solid-black, 0.05);
  background-color: $white;
  width: 100%;
  border: 0;
  transition: box-shadow 0.2s ease-in-out;

  &.outlined,
  &.transparent {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.outlined {
    border: 1px solid $bluish-gray;
  }
}
