@import '~global/style-config/media-query';

.subLabel {
 @include sm-only {
  font-size: 12px;

  > * {
    font-size: inherit;
  }
 }
}