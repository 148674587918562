@import '~global/style-config/media-query';

.button {
  padding: 7px 13px;
  border-radius: 1.5rem;
  font-size: 14px;
  
  @include on-md {
    padding: 10px 40px;
    min-width: 130px;
  }

  &.smallPadding {
    padding: 10px 18px;
  }

  &.mediumPadding {
    padding: 10px 25px;
  }
}
