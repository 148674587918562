@import '~global/style-config/media-query';

.checkInCard {
  .name {
    font-size: 14px;
    font-weight: 500;

    @include on-md {
      font-size: 18px;
    }
  }
}

.addCheckIn {
  .modalTitle {
    font-size: 18px;
  }

  .cancelBtn {
    min-width: 65px;
    height: 25px;
  }
}

.editStakeholderCheckin {
  pointer-events: none;
}
