@import '~global/style-config/colors';

.progressCheck {
  .questionWrapper {
    border-left: 1px solid $bluish-gray;

    &:last-child {
      border-left: 0;
    }

    .questionTag {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: -15px;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background-color: $primary;
      color: $white;
      font-weight: 500;
    }

    .question {
      font-size: 16px;
      font-weight: 500;
    }

    .chartTitle {
      font-size: 16px;
    }

    .textAnswerWrapper {
      background-color: rgba($bluish-gray, 0.2);
      border-radius: 5px;
      font-size: 16px;
    }
  }
}
