@import '~global/styles';

.formCheck {
  $checboxWidth: 18px;

  position: relative;
  padding-left: $checboxWidth;
  min-height: 18px;
  margin-bottom: 10px;

  input {
    display: none;
  }

  input {
    & + label {
      margin-left: 10px;
      cursor: pointer;
      font-weight: 700;

      &::before {
        position: absolute;
        left: 0;
        content: '';
        width: $checboxWidth;
        height: $checboxWidth;
        border: 1px solid $bluish-gray;
        border-radius: 9px;
        transition-duration: 0.3s;
      }

      &.disabled {
        opacity: 0.5;
        font-weight: 400;
        cursor: auto;
      }

      &.readOnly {
        font-weight: 400;
        cursor: auto;
      }
    }

    &:checked {
      & + label {
        font-weight: 400;

        &::before {
          background-image: url('~assets/icons/styled-checkbox-active.svg');
          background-repeat: no-repeat;
          border: 0;
        }
      }
    }
  }
}
