.tagsContainer {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px 10.5px;
  display: grid;
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus-within {
    border-color: #3a9cd6;
    box-shadow: 0 0 0 0.2rem  rgba(25, 84, 119, .25);
  }

  .tag {
    background-color: #E9F1F8;
    padding: 5px 10px;
    display: inline-flex;
    border-radius: 9999px;
    margin: 5px;

    span {
      margin-right: 5px;
    }
  }

  .input {
    width: 100%;
    outline: none;
    border: none;

    &.hasTags {
      margin: 5px;
      width: 200px;
      border: 2px dashed rgba(0,0,0,0.1);
      border-radius: 9999px;
      padding: 0 12px;
    }
  }
}
