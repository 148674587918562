.addStakeholder {
  .modalTitle {
    font-size: 18px;
  }
}

.stakeholderNameColumn {
  max-width: 150px;

  .stakeholderName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.stakeholderEmailColumn {
  max-width: 125px;

  @media screen and (min-width: 1440px) {
    max-width: 150px;
  }
}

.stakeholderRelationshipColumn {
  max-width: 80px;
  white-space: nowrap;

  @media screen and (min-width: 1440px) {
    max-width: 120px;
  }
}
