@import '~global/styles';

.container {
  width: 100%;
  max-width: unset;

  @include on-md {
    grid-template-rows: var(--headerHeight) 1fr;
    min-height: 100vh;
    display: grid;
  }
}

.sidebar {
  height: 100%;

  .navLink {
    opacity: 0.7;

    &:hover,
    &.active {
      text-decoration: none;
      background-color: $primary-dark;
      opacity: 1;

      .iconWrapper {
        color: $secondary;
      }
    }
  }
}

.dashboard {
  padding: 15px;
  padding-top: calc(var(--mobileHeaderHeight) + 15px);

  @include on-md {
    padding: 40px;
  }
}

.addButton {
  width: 6px;
  height: 6px;
}

.placeholderIcon {
  position: absolute;
  left: 20px;
  top: 13px;
}

.dividerLine {
  $lineColor: #d6dcde;

  border-top: 1px solid $lineColor;
  margin-top: 7.5px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.urlFavicon {
  position: absolute;
  top: 18px;
}

.urlTitle {
  margin-left: 44px;
}
