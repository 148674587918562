@import '~global/styles';

.surveyWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 60%;

  .row {
    flex: 1;
  }

  .contentWrapper {
    width: 100%;

    .questionWrapper {
      $checboxWidth: 18px;

      width: auto;
      padding: 20px;
      input {
        display: none;
      }

      input {
        & + label {
          margin-left: 10px;
          cursor: pointer;

          &::before {
            position: absolute;
            left: 0;
            content: '';
            width: $checboxWidth;
            height: $checboxWidth;
            border: 1px solid $bluish-gray;
            border-radius: 9px;
            transition-duration: 0.3s;
          }

          &.disabled {
            opacity: 0.5;
            font-weight: 400;
            cursor: auto;
          }
        }

        &:checked {
          & + label {
            font-weight: 700;

            &::before {
              background-image: url('~assets/icons/styled-checkbox-active.svg');
              background-repeat: no-repeat;
              border: 0;
            }
          }
        }
      }
    }
  }

  .logo {
    @include md-only {
      width: 150px;
    }
  }

  .button {
    text-align: right;
    margin-bottom: 40px;
  }
}
