@import '~global/style-config/media-query';

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;

  @include on-md {
    width: 130px;
  }

  &.responsive {
    @include sm-only {
      padding: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    svg {
      margin-right: 0;

      @include on-sm {
        width: 8px;
        height: 8px;
        margin-right: 5px;
      }
    }

    span {
      display: none;
      white-space: nowrap;

      @include on-sm {
        display: inline-block;
      }
    }
  }

  svg {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }

  span {
    display: inline-block;
    white-space: nowrap;
  }
}
