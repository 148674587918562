@import '~global/style-config/media-query';

.h2 {
  font-size: 16px;
  font-weight: 500;

  @include on-md {
    font-size: 21px;
  }
}
