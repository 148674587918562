@import '~global/style-config/colors';

.attendees {
  padding-bottom: 40px;
  border-bottom: 1px solid $bluish-gray;
  margin-bottom: 20px;
}

.files {
  display: flex;

  .fileDisplay {
    margin-right: 10px;
  }
}
