.unorderedList {
  position: relative;

  .listItem {
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.listItem {
  display: flex;
  align-items: center;

  .bullet {
    width: 10px;
    height: 10px;
    align-self: flex-start;
    margin-right: 15px;
    margin-top: 5px;
  }
}
