@import '~global/styles';

.filterWrapper {
  display: flex;
  align-items: center;

  > * {
    margin: 0;
    margin-right: 10px;
  }

  > p {
    font-weight: 600;
  }
}
