@import '~global/style-config/colors';

.avatar {
  position: relative;
  width: 50px;

  .close {
    right: 0px;
    position: absolute;
    background-color: #cecece;
    color: #06429c;
    border-radius: 94px;
    padding-left: 7px;
    padding-right: 7px;
  }

  .image {
    width: 50px;
  }
}

.progressCheck {
  .questionWrapper {
    border-left: 1px solid $bluish-gray;

    &:last-child {
      border-left: 0;
    }

    .questionTag {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: -15px;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background-color: $primary;
      color: $white;
      font-weight: 500;
    }

    .question {
      font-size: 16px;
      font-weight: 500;
    }

    .chartTitle {
      font-size: 16px;
    }

    .textAnswerWrapper {
      background-color: rgba($bluish-gray, 0.2);
      border-radius: 5px;
      font-size: 16px;
    }
  }
}
.imageWrapper {
  margin-right: 0 !important;
  margin-bottom: 5px;
}

.selectedClient {
  flex-direction: column;

  span {
    text-align: center;
    max-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
