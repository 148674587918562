@import '~global/style-config/colors';

.stage {
  padding: 5px 13px;
  background-color: rgba(25, 84, 119, 0.1);
  width: 130px;
  border-radius: 5px;

  p {
    color: $primary;
    letter-spacing: 0.19px;
    font-size: 12px;
    line-height: 1.2;
    margin: 0;
  }

  &:hover {
    background: linear-gradient(
      46.78deg,
      #2974c1 0%,
      #6acafc 100%
    );
    p {
      color: white;
    }
  }
}

.access {
  text-align: center;
}

.profileImage {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-width: 40px;
}
