@import '~global/style-config/colors';
@import '~global/style-config/media-query';

.rightSidebar {
  @include xl-only {
    > div {
      border-top: 1px solid $bluish-gray;
      padding-top: 30px;
    }
  }

  @include on-md {
    border-left: 1px solid $bluish-gray;
  }
}