@import '~global/style-config/media-query';

.reminderType {
  padding-right: 110px;
  font-size: 12px;

  @include on-md {
    font-size: 14px;
  }
}
