@import '~global/style-config/colors';

.thumbnailPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  max-width: 40px;
  height: 40px;
  max-height: 40px;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 700;
  color: $white;
  text-transform: uppercase;
}
