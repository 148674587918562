@import '~global/style-config/media-query';

.h1 {
  font-size: 16px;
  font-weight: 500;

  @include on-md {
    font-size: 24px;
  }
}
