@import '~global/styles';

.sendMailWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .row {
    flex: 1;
  }

  .leftWrapper {
    align-items: center;
    display: flex;
    justify-content: center;

    .contentWrapper {
      max-width: 350px;
      width: 100%;
    }

    .form {
      margin: auto;
    }
  }

  .userButton {
    margin-top: 50px;
  }

  .rightWrapper {
    position: relative;
    align-items: center;
    display: flex;

    .bottomRightImage {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .contentWrapper {
      text-align: center;
      width: 60%;
      margin: auto;

      h1,
      p {
        text-align: left;
      }

      .divider {
        width: 50px;
        height: 5px;
        background-color: $secondary;
        margin-bottom: 20px;
      }
    }

    .welcomeDesctiption {
      max-width: 320px;
      font-size: 14px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
