@import '~global/styles';

.loginWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .row {
    flex: 1;
  }

  .leftWrapper {
    position: relative;
    align-items: center;
    display: flex;
    padding: 50px 36px;
    overflow: hidden;

    .topLeftImage {
      position: absolute;
      left: -20px;
      top: -20px;
      width: 100px;

      @include on-md {
        left: 0;
        top: 0;
        width: unset;
      }
    }

    .contentWrapper {
      text-align: center;
      width: 100%;

      h1 {
        text-align: left;
      }

      .divider {
        width: 50px;
        height: 5px;
        background-color: $secondary;
        margin-bottom: 20px;
      }

      .welcomeDescription {
        max-width: 320px;
        font-size: 18px;
        text-align: left;
      }
    }
  }

  .rightWrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 66px 30px;

    .contentWrapper {
      max-width: 350px;
      width: 100%;

      h1 {
        @include md-only {
          font-size: 14px;
        }
      }
    }

    .logo {
      @include md-only {
        width: 150px;
      }
    }

    .form {
      margin: auto;
    }
  }

  .userButton {
    margin-top: 50px;
  }
}
