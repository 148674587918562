@import '~global/style-config/colors';
@import '~global/style-config/media-query';

.simpleTopNavbarWrapper {
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .simpleTopNavbar {
    display: inline-flex;

    .navTitle {
      font-size: 18px;
      color: $gray-dark;
      padding-bottom: 5px;
      white-space: nowrap;
      margin-right: 20px;

      @include on-md {
        margin-right: 30px;
      }

      &.active {
        color: $primary;
        border-bottom: 3px solid $primary;
      }
    }
  }
}
.overflow {
  overflow: unset;
}
