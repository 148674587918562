@import '~global/style-config/media-query';
@import '~global/style-config/colors';

@mixin resetChildWrapper {
  > div {
    padding: 0 !important;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
    border: 0;

    > div {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.modal {
  .close {
    position: absolute;
    right: 14px;
    top: 14px;

    @include on-md {
      right: 20px;
      top: 20px;
    }
  }

  .back {
    line-height: 0;
    margin: auto 0;

    svg {
      > g > g {
        fill: $primary;
      }
    }
  }
}

.mimicNative {
  @include sm-only {
    > div {
      margin: 0;
      
      > div {
        border: 0;
        border-radius: 0;
        min-height: 100vh;
      }
    }
  
    .header {
      justify-content: flex-start;
      border-bottom: 1px solid #F1F5F8;
      padding: 0 17px;
      height: var(--mobileHeaderHeight);
      position: fixed;
      background: white;
      width: 100%;
      z-index: 1;
      align-items: center;
  
      .headerTitle {
        padding-left: 28px;
        font-size: 16px;
      }
    }
  
    .nativeBody {
      padding: 16px;
      padding-top: calc(var(--mobileHeaderHeight) + 16px);
      
      @include resetChildWrapper;
    }
  }
}
