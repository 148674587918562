.pdfViewerWrapper {
  .pdfViewer {
    width: 100%;
    min-height: 850px;
  }
}

.pdfViewerWrapperMobile {
  display: flex;
  align-items: center;
  justify-content: center;
}
