@import '~global/style-config/colors';

.formControl {
  padding-top: 10px;
  padding-bottom: 10px;
  height: auto;
  align-items: center;
  font-size: 14px;
  min-height: 43px;

  &.withIcon {
    padding-left: 47px;
  }
}

.formControlWrapper {
  position: relative;
}

.dropdownCard {
  width: 110%;
  padding: 0;
  -webkit-box-shadow: 0 3px 6px 0 rgba($solid-black, 0.2);
  box-shadow: 0 3px 6px 0 rgba($solid-black, 0.2);

  .dropdownItem {
    padding: 10px 20px;
  }
}
