@import '~global/styles';
@import '~pages/Coach/styles.module';

.devPlanCard {
  .rateTitle {
    font-size: 10px;
    text-align: center;
  }

  .rateSubtitle {
    font-size: 8px;
    text-align: center;
  }

  .rateBox {
    max-width: 43px;
    max-height: 30px;
    text-align: center;
    font-weight: 500;
    color: $primary;
    padding: 6px;
    margin: auto;
    background-image: none !important;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .actionItemInput {
    flex: 1;
  }
}
