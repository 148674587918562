.activityFeed {
  
}

.customTable {
  td {
    max-width: 26vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: 1440px){
      max-width: 42vw;
    }
  }
}