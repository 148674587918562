@import '~global/style-config/colors';
@import '~global/style-config/media-query';
@import '~global/style-utils';

@mixin floatingSpan {
  span {
    position: absolute;
    bottom: 0;
    width: 100px;
    right: 0;
    width: 90%;

    @include on-xl {
      width: 61%;
    }
  }
}

.resourceLibrary {
  .searchBar {
    max-width: 210px;
  }

  .resourceCard {
    margin-bottom: 10px;
    padding: 0;
    cursor: pointer;

    @include on-md {
      margin-bottom: 20px;
      padding-right: 20px;

      &:nth-child(3n) {
        padding-right: 0;
      }
    }

    .resourceContent {
      position: relative;

      @include sm-only {
        @include clampText(3);
        @include floatingSpan;
      }

      &.floatReadMore {
        @include clampText(3);
        @include floatingSpan;
      }

      span {
        white-space: nowrap;

        .touchableOpacity {
          display: inline;
          white-space: nowrap;
          background-color: $white;
          line-height: 14px;
        }
      }
    }
  }

  .recommendedResourcesWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    // border-bottom: 1px solid $bluish-gray;

    .card {
      background: transparent;
      box-shadow: none;

      .recommendedContainer {
        @include on-xl {
          // 100% - image width - padding
          width: calc(100% - 115px - 20px);
        }
      }
    }

    .title {
      @include on-xl {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .additionalResourceWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .card {
    &:hover {
      box-shadow: 4.6px 4.8px 10px rgba(0, 0, 0, 0.025),
        37px 38px 80px rgba(0, 0, 0, 0.05);
    }

    .siteImage {
      width: 100%;
      min-height: 115px;
      border-radius: 5px;
      object-fit: cover;

      @include on-xl {
        width: 115px;
      }
    }
  }

  .seeMoreBtn {
    min-width: 130px;
    margin-top: 10px;

    @include on-md {
      margin-top: 0;
    }
  }

  .siteDetails {
    $lineColor: #d6dcde;

    display: flex;
    align-items: center;

    .siteIcon {
      min-width: 30px;
      min-height: 30px;
      max-width: 30px;
      max-height: 30px;
      margin-right: 10px;
      border: 0.5px solid $lineColor;
      border-radius: 6px;
    }

    .siteName {
      color: $gray-dark;
      font-size: 12px;
    }
  }
}
