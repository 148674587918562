@import '~global/style-config/colors';

.fileDisplay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba($gray-dark, 0.1);
  max-width: 140px;
  min-width: 140px;
  min-height: 110px;
  margin-right: 10px;
  border-radius: 5px;
  position: relative;

  .iconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }

  .fileName {
    font-size: 12px;
    text-align: center;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .removeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}
