.checkInCard {
  .name {
    font-size: 18px;
    font-weight: 500;
  }
}

.addCheckIn {
  .modalTitle {
    font-size: 18px;
  }

  .cancelBtn {
    min-width: 65px;
    height: 25px;
  }
}

.editStakeholderCheckin {
  pointer-events: none;
}
