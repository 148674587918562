@import '~global/style-config/colors';

.profilePictureWrapper {
  min-width: 140px;
  min-height: 140px;
  max-width: 140px;
  max-height: 140px;
  overflow: hidden;
  border-radius: 70px;
  position: relative;

  .profilePicture {
    min-width: 140px;
    min-height: 140px;
    max-width: 140px;
    max-height: 140px;
  }

  .editProfile {
    width: 140px;
    height: 50px;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($solid-black, 0.5);
  }
}
