.dropdown {
  border: none;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
  top: 20px !important;

  &::after {
    content: '';
    position: absolute;
    right: 10px;
    top: -10px;
    z-index: -1;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #FFFFFF;
    filter: drop-shadow(0 -6mm 4mm rgba(0,0,0,0.8));
    z-index: -1;
  }

  .dropdownItem {
    &:active {
      background: #e9ecef;
    }
  }
}
