$primary-light: #2974c1;
$primary: #195477;
$primary-dark: #0f3e5c;

$secondary: #f88801;

$gray: #f8f8fa;
$gray-dark: #9c9c9c;
$bluish-gray: #d8dde8;

$danger: #f00;

$white: #ffffff;

$black: #333333;
$solid-black: #000000;

$dark-blue: #052E46;
$blue: #0B4162;

$gradient-primary: linear-gradient(
  46.78deg,
  $primary-light 0%,
  #6acafc 100%
);
